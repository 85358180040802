import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { actionSwitchPage } from '../../redux/currentPage/action';
import AddComplementaryPlaylist from './AddComplementaryPlaylist';
import { actionShowMessage } from '../../redux/support/action';
import AddImageToPlaylist from './AddImageToPlaylist';
import DeleteImageToPlaylist from './DeleteImageToPlaylist';
import PublishPlaylistModal from './PublishPlaylistModal';
import InviteUserModal from './InviteUserModal';
import DeleteDialog from './DeleteDialog';
import { DATA_PARENTS, MessageType, openModalType } from '../../utils/constants';
import { actionDeleteTagFromSystem } from '../../redux/tags/action';
import {
  actionChangeChannelUserStatus,
  actionDeleteChannel,
} from '../../redux/channels/action';
import LeaveChannel from './LeaveChannel';
import AddMemberModal from './AddMemberModal';
import EditContactModal from './EditContactModal';
import ConfirmDeleteContact from './ConfirmDeleteContact';
import AddTagModal from './AddTagModal';
import EmbedPageModal from './EmbedPageModal';
import AddPlaylistToPlaylistWindow from './AddPlaylistToPlaylistWindow';
import ConfirmDialog from './ConfirmDialog';
import CropUserAvatar from './CropUserAvatar';
import ApplicationSettings from '../../pages/SettingsSection/ApplicationSettings';
import WarningDeleteItem from './WarningDeleteItem';
import ConfirmModalExitPlaylist from './ConfirmModalExitPlaylist';
import RenameModal from './RenameModal';
import ViewersProgress from './ViewersProgress';
import CreateChannelModal from './CreateChannelModal';
import ConfirmModalUniversal from './ConfirmModalUniversal';
import SmartModal from './SmartViewModal';
import CreatePlaylistFromMultiselect from './CreatePlaylistFromMultiselect';
import TagModal2023 from './TagModal2023';
import EditBoardColumnModal from './EditBoardColumnModal';
import ResetColorOverlayModal from './ModalOverlayModal/ResetColors';
import CropPlaylistCover from './CropPlaylistCover';
import Share from '../DropDownOption/Share';
import CreateLinkModal from './CreateLinkModal';
import UploadModal from './Upload';
import UnpublishModal from './UnpublishModal';
import UPVPreview from './UPVPreview';
import DownloadPlaylistModalOutSide from './DownloadPlaylistModalOutSide';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import { actionCloseModal } from '../../redux/user/action';
import AddAIBlock from './AddAIBlock';
import AddFileModal from './AddFileModal';
import AICancelVectorizeConfirmModal from './AICancelVectorizeConfirmModal';


const ModalSelector = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { myChannels } = useSelector((state) => state.channels);
  const {
    openModal,
    openModalOverlayModal,
    dataPayload,
  } = useSelector((state) => state.user);

  const close = useCallback(() => {
    dispatch(actionRemoveAllSelected());
    dispatch(actionCloseModal());
  }, [dispatch]);

  return (
    <>

      {openModalOverlayModal === openModalType.ResetColorOverlayModal && <ResetColorOverlayModal />}
      {openModal === openModalType.UploadModal2023 && <UploadModal />}
      {openModal === openModalType.TagModal2023 && <TagModal2023 />}
      {openModal === openModalType.EditBoardColumn && <EditBoardColumnModal />}
      {openModal === openModalType.CreatePlaylistFromMultiselect && <CreatePlaylistFromMultiselect /> }
      {openModal === openModalType.UnpublishModal && (<UnpublishModal />)}
      {openModal === openModalType.SmartViewModal && <SmartModal />}
      {openModal === openModalType.UPVPreview && <UPVPreview />}
      {openModal === openModalType.CreateChannel && <CreateChannelModal />}
      {openModal === openModalType.ViewersProgress && <ViewersProgress />}
      {openModal === openModalType.ConfirmModal && <ConfirmDialog />}
      {openModal === openModalType.AddAIBlock && <AddAIBlock />}
      {openModal === openModalType.ConfirmModalUniversal && <ConfirmModalUniversal />}
      {openModal === openModalType.RenamePlaylistModal && <RenameModal />}
      {openModal === openModalType.ConfirmModalExitPlaylist && <ConfirmModalExitPlaylist /> }
      {openModal === openModalType.CropUserAvatar && <CropUserAvatar />}
      {openModal === openModalType.CropPlaylistCover && <CropPlaylistCover />}
      {openModal === openModalType.AddImageToPlaylist && <AddImageToPlaylist />}
      {openModal === openModalType.AddFileModal && <AddFileModal />}
      {openModal === openModalType.embedPageModal && <EmbedPageModal />}
      {openModal === openModalType.WarningDeleteItem && <WarningDeleteItem />}
      {openModal === openModalType.DeleteImageToPlaylist && <DeleteImageToPlaylist />}
      {openModal === openModalType.AddComplementaryPlaylist && <AddComplementaryPlaylist /> }
      {openModal === openModalType.LeaveChannel && <LeaveChannel />}
      {openModal === openModalType.InviteUserModal && <InviteUserModal />}
      {openModal === openModalType.EditContact && <EditContactModal />}
      {openModal === openModalType.ConfirmDeleteContact && <ConfirmDeleteContact />}
      {openModal === openModalType.AddMemberModal && <AddMemberModal />}
      {openModal === openModalType.AddTagModal && <AddTagModal />}
      {openModal === openModalType.ApplicationSettings && <ApplicationSettings />}
      {openModal === openModalType.DeleteSmartview && <DeleteDialog title={t('deleteThisSmartviewT')} /> }
      {openModal === openModalType.AddPlaylistToPlaylistWindow && <AddPlaylistToPlaylistWindow /> }
      {openModal === openModalType.CreateLinkModal && <CreateLinkModal />}
      {openModal === openModalType.PublishPlaylistAddChannel && <PublishPlaylistModal isAddChannel />}
      {openModal === openModalType.PublishPlaylistModal && <PublishPlaylistModal />}
      {openModal === openModalType.DownloadPlaylistModalOutSide && <DownloadPlaylistModalOutSide />}
      {openModal === openModalType.AICancelVectorizeConfirmModal && <AICancelVectorizeConfirmModal />}
      {openModal === openModalType.ShareMultiple && (
      <Share
        close={close}
        dataParent={`${DATA_PARENTS.SharedComponent}`}
        isMakerStyles
        isMultiple
      />
      )}
      {openModal === openModalType.Share && (
      <Share
        close={close}
        dataParent={`${DATA_PARENTS.SharedComponent}`}
        isMakerStyles={dataPayload.isMakerStyles}
        dataPayload={dataPayload}
      />
      )}
      {openModal === openModalType.DeleteTag && (
      <DeleteDialog
        title={t('deleteThisTagT')}
        deleteCb={(tagId, tagName) => {
          dispatch(actionShowMessage({
            type: MessageType.DeleteTag,
            tagName,
          }));
          dispatch(actionDeleteTagFromSystem(tagId, history));
        }}
      />
      )}
      {openModal === openModalType.DeleteUser && (
      <DeleteDialog
        title={t('deleteThisUserT')}
        deleteCb={(data) => {
          dispatch(
            actionChangeChannelUserStatus(
              data.userid,
              'Deleted',
              data.channelId,
            ),
          );
          dispatch(
            actionShowMessage({
              type: MessageType.DeleteSubscriber,
              name: data.name,
            }),
          );
        }}
      />
      )}
      {openModal === openModalType.DeleteChannel && (
      <DeleteDialog
        title={t('deleteThisChannelT')}
        message={t('deleteThisChannelMessageT')}
        deleteCb={(channelId, nextChannel) => {
          dispatch(
            actionShowMessage({
              type: MessageType.DeleteChannel,
              title: myChannels[channelId]?.name,
            }),
          );
          dispatch(actionDeleteChannel(channelId, nextChannel, history));
          dispatch(actionSwitchPage({ idDragPage: null }));
        }}
      />
      )}
    </>
  );
};

export default ModalSelector;
