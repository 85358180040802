export const SAVE_USER = 'SAVE_USER';
export const IS_DOWNLOAD_USER = 'IS_DOWNLOAD_USER';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_TRASH_MESSAGE = 'SHOW_TRASH_MESSAGE';
export const CLOSE_TRASH_MESSAGE = 'CLOSE_TRASH_MESSAGE';
export const SHOW_COPY_MESSAGE = 'SHOW_COPY_MESSAGE';
export const CLOSE_COPY_MESSAGE = 'CLOSE_COPY_MESSAGE';
export const CLOSE_ERROR = 'CLOSE_ERROR';

export const ServiceUser = {
  OpenModal: '[User] User open Modal',
  CloseModal: '[User] User close Modal',
  OpenAIModal: '[User] User open AI Modal',
  CloseAIModal: '[User] User close AI Modal',
  OpenModalOverlayModal: '[User] User open ModalOverlayModal',
  CloseModalOverlayModal: '[User] User close ModalOverlayModal',
  DeleteLinkedAccount: '[User] Delete Linked Account REDUX',
  Logout: '[User] Logout User',
  Login: '[User] Login User',
  GoogleLoginFlow: '[User] Google Login flow',
  FacebookLoginFlow: '[User] Facebook Login flow',
  SetResponseError: '[User] Set Server Response Error Message',
  Request: '[User] Request data',
  signupUser: '[User] Signup User',
  SetDefaultState: '[Service] Set Default State',
  switchFolder: '[switchFolder] change the folder displayed open on the route',
  BlockUnloading: '[switchFolder] Block Browser Unloading',
  UnblockUnloading: '[switchFolder] Unblock Browser Unloading',
  LazyLoading: '[switchFolder] LazyLoading is Active',
  switchDynamicCollection: '[ServiceUser] Set user',
  SetUpIsAdmin: '[ServiceUser] Set Up IsAdmin',
  goToLink: '[ServiceUser] Go to link',
  UpdateGoogleAccessToken: '[ServiceUser] Update Google AccessToken',
  CreateLinkedAccountS: '[ServiceUser] Create LinkedAccount S',
  HandleIntegrationPickedFilesS: '[ServiceUser] Handle Dropbox Picked Files S',
  HandleIntegrationFileLinkS: '[ServiceUser] Handle Dropbox File Link S',
  ADD_INTO_SEQUENCE: '[ServiceUser] Add logic into database changes sequence',
  _setDatabaseSequenceIsEmpty: '[ServiceUser] _setDatabaseSequenceIsEmpty',
  unsubscribe: '[ServiceUser] Unsubscribe to playlist',
  Player: {
    SetContentWidthMode:
      '[ServiceUser] Set Player Settings contentWidthMode value',
    ChangeZoom: '[ServiceUser] Set Player Settings Zoom value',
    ChangeZoomAuto: '[ServiceUser] Auto Set Player Settings Zoom value',
    SoundVolume: '[ServiceUser] Set Player Settings SoundVolume value',
    ChangeFullscreen: '[ServiceUser] Set Player Settings Fullscreen value',
    IsCaptionsAndApproveButtonsHidden:
      '[ServiceUser] Set isCaptionsAndApproveButtonsHidden value',
    isCaptionsHidden: '[ServiceUser] isCaptionsHidden',
    isApproveButtonsHidden: '[ServiceUser] isApproveButtonsHidden',
  },
  Tags: {
    Rename: '[ServiceUser/Tags] Rename user Tag',
  },
};
