export const EDIT_TITLE = 'EDIT_TITLE';
export const SWITCH_PAGE = 'SWITCH_PAGE';
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const UPDATE_CURRENT_PLAYLIST_TIME = 'UPDATE_CURRENT_PLAYLIST_TIME';
export const UPDATE_LAYERS = 'UPDATE_LAYERS';
export const PAGE_IS_DOWNLOAD = 'PAGE_IS_DOWNLOAD';
export const SAVE_CURRENT_PLAYLIST_TIME = 'SAVE_CURRENT_PLAYLIST_TIME';
export const GET_CURRENT_PLAYLIST = 'GET_CURRENT_PLAYLIST';
export const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';
export const SAVE_CURRENT_PLAYLIST = 'SAVE_CURRENT_PLAYLIST';
export const DRAG_PAGE = 'DRAG_PAGE';
export const DRAG_MANY_PAGE = 'DRAG_MANY_PAGE';
export const GO_TO_VIEW_PAGE = 'GO_TO_VIEW_PAGE';
export const PAGE_WILL_DOWNLOAD = 'PAGE_WILL_DOWNLOAD';
export const SET_DRAGGABLE_BLOCKS = 'SET_DRAGGABLE_BLOCKS';
export const HAS_DRAGGABLE = 'HAS_DRAGGABLE';

export const CurrentPage = {
  Share: {
    UPDATE_SHARE_STATE: '[CurrentPage-share] UPDATE_SHARE_STATE',
    GET_CURRENT_SHARED_PAGE: '[CurrentPage-share] GET_CURRENT_SHARED_PAGE',
  },
  SetResetScale: '[CurrentPage] Set ResetScale value',
  clearId: '[CurrentPage] clear id',
  RemoveBlock: '[CurrentPage] RemoveBlock',
  ReplaceSingleBlock: '[CurrentPage] ReplaceSingleBlock',
  ChangeImageBlockData: '[CurrentPage] Change Image Block Data',
  UpdateSingleBlock: '[CurrentPage] Update Single Block REDUX',
  InsertSingleBlock: '[Blocks] Insert Single Block REDUX',
  ReplaceBlockS: '[Blocks] Replace Block SAGA',
  ResizeLibraryWidget: '[CurrentPage] Toggle size right bar',
  ChangeStateComponentButton: '[CurrentPage] Change state approve Button',
  ChangeStateComponentButtonREDUX:
    '[CurrentPage] Change state approve Button REDUX',
  ShowLibraryWidget: '[CurrentPage] Toggle show Library right bar ',
  ToggleSideBarExpand: '[CurrentPage] ToggleSideBarExpand REDUX',
  ShowPagesWidget: '[CurrentPage] Toggle show Pages right bar',
  ToggleIsCardView: '[CurrentPage] Toggle isCardView',
  handleFolderTitleChangeREDUX:
    '[CurrentPage] handle Folder Title Change REDUX',
  ToggleLibraryDrag: '[CurrentPage] Toggle Library Drag',
  ChangeTypeBlock: '[EditPage]  Change Type Block ',
  CreateEditableBlockWithState: '[EditPage]  Create Editable Block With State ',
  ClearCurrentPage: '[EditPage] Clear Current Page - to defaultState',
  ToggleComponentIsHidden: '[EditPage] Toggle Component IsHidden',
  ClearBlockWithFile: '[EditPage] Clear block with File',
  BulkToggleComponentIsHidden: '[EditPage]  Bulk Toggle Component IsHidden',
  GetViewPage: '[ViewPage]  Get view Page',
  ClearBlockPreSaveFlag: '[CurrentPage]  Clear Block PreSave Flag',
  saveInputPlaylist: 'Edit Input Playlist',
  toggleHideAdditionalInformation: 'toggle Hide Additional Information',
  TryPublishPlaylist: '[CurrentPage] user tried publish playlist',
  ClearTryPublishPlaylist: '[CurrentPage] Clear Try Publish Playlist',
  SetAvailabilityDate: '[CurrentPage] Set Availability Date',
  UpdatePlaylistLastModifiedDate:
    '[CurrentPage] Update Playlist LastModified Date',
  UpdateCurrentPageTitle: '[CurrentPage] Update CurrentPage Title',
  ToggleIsCollapsedContent: '[CurrentPage] Toggle IsCollapsedContent',
  RemoveBlockR: '[CurrentPage] Remove Block Redux only',
  GetShareLink: '[CurrentPage] Get Share Link',
  RenameLinkedPage: '[CurrentPage] rename Linked Page',
  UpdateLinkPageDuration: '[CurrentPage] Update LinkPage Duration',
  ChangeTextElementBlockRedux: '[CurrentPage] Change TextElement Block Redux',
  RemoveLinkPageR: '[CurrentPage] RemoveLinkPageR Redux',
  DuplicateLinkPageS: '[CurrentPage] Duplicate LinkPage SAGAS',
  UpdateCollapseLinkPageRS: '[CurrentPage] Update Collapse LinkPage  SAGAS Redux',
  CreateLinkPageR: '[CurrentPage] Create LinkPage REDUX',
  CreateApproveButtonS: '[CurrentPage] create approve button SAGAS',
  UpdateApproveButtonS: '[CurrentPage] Update approve button SAGAS',
  UpdateApproveButtonRedux: '[CurrentPage] update approve button Redux',
  UpdateIsShowCaptionState: '[CurrentPage] Update IsShowCaption State',
  UpdateCaption: '[CurrentPage] Update Caption',
  Summarize: '[CurrentPage] Get genereted summarize',
  AIProcessingToggleLoading: '[CurrentPage] start/finish summarize loading',
  ToggleSettingPreviewRS: '[CurrentPage] Toggle Setting Preview',
  SaveSummarize: '[CurrentPage] save summarize result',
  DeleteApproveButtonS: '[CurrentPage] delete approve button SAGAS',
  UpdateRemixState: '[CurrentPage] Update Remix State',
  LinkPageTypeUploadR: '[CurrentPage] Update LinkPageTypeUploadR',
  ClickToApproveButtonS: '[CurrentPage] click to approve button SAGAS',
  ClickToApproveButtonR:
    '[CurrentPage] click to approve button Redux ~mutation~',
  AddEmptyLinkPageToPlaylist: '[CurrentPage] Add Empty LinkPage To Playlist',
  RemoveLinkPageFromPlaylist: '[CurrentPage] Remove LinkPage From Playlist',
  RemoveLinkPagesFromPlaylist: '[CurrentPage] Remove LinkPages From Playlist(Multiple)',
  SelectItemInSmartfile: '[CurrentPage] Select Item In Smartfile',
  ClearItemsSelectionInSmartfile: '[CurrentPage] Clear Items Selection In Smartfile',
  ChangeLinkPageTypeR: '[CurrentPage] Change LinkPageType REDUX',
  ChangeLinkPageTypeS: '[CurrentPage] Change LinkPageType SAGAS ',
  CancelUploadAndChangeLinkPageTypeS:
    '[CurrentPage] Cancel Upload And Change LinkPage Type SAGAS ',
  UpdateLinkPage: '[CurrentPage] Update LinkPage',
  ManyUpdatePositionLinkPages: '[CurrentPage] many  Update LinkPage REDUX',
  UpdatePositionLinkPagesMaker:
    '[CurrentPage] many  Update LinkPage MAKER REDUX',
  UpdateLinkPageR: '[CurrentPage] Update LinkPage REDUX',
  ReplaceLinkPagePlaylistPageR:
    '[CurrentPage] Replace LinkPage Play listPage REDUX',
  ReplaceLinkPagePlaylistPage: '[CurrentPage] Replace LinkPage Play listPage',
  MovePageWithinPlaylist: '[CurrentPage] Move Page Within Playlist',
  MovePageWithinPlaylistMaker: '[CurrentPage] Move Page Within Playlist Maker',
  CreateLibLinkPagePlaylist: '[CurrentPage] Create Lib LinkPage Playlist',
  MultipleCreateLibLinkPagePlaylist:
    '[CurrentPage] Multiple Create Lib LinkPage Playlist',
  CreateLinkPageWithURL: '[CurrentPage] Create with Url',
  CreateAndUploadLinkPage: '[CurrentPage] Create And Upload LinkPage',
  ClearLinkPageType: '[CurrentPage] Clear LinkPage Type',
  TurnIntoBlockSMaker: '[CurrentPage] Turn Into Block Maker SAGAS',
  UpdateRemixStateUPV: '[CurrentPage] Update Remix State UPV',
  DeleteLinkPageR: '[CurrentPage] Delete LinkPage REDUX',
  DeleteLinkPageSS: '[CurrentPage] Delete LinkPage SAGA-Sockets',
  AddManyLinkPagesR: '[CurrentPage] Add LinkPages to Playlist REDUX',
  AddManyLinkPagesSS: '[CurrentPage] Add LinkPages to Playlist SAGA-Sockets',
  RemoveManyLinkPagesSS:
    '[CurrentPage] Remove LinkPages from Playlist SAGA-Sockets',
  RemoveManyLinkPagesR: '[CurrentPage] Remove LinkPages from Playlist REDUX',
  InsertGoogleUploadBlockSS:
    '[CurrentPage] Insert Google Upload Block SAGAS-Sockets',
  InsertGoogleUploadBlockR: '[CurrentPage] Insert Google Upload Block REDUX',
  UpdateGoogleUploadBlockSS:
    '[CurrentPage] Update Google Upload Block SAGAS-Sockets',
  UpdateGoogleUploadBlockR: '[CurrentPage] Update Google Upload Block REDUX',
  RenameEdit: '[CurrentPage] Rename or edit in Maker',
  AddElementToBeScrollTo: '[CurrentPage] AddElementToBeScrollTo',
  ClearToBeScrollTo: '[CurrentPage] ClearToBeScrollTo',
  setSingleUserShareState: '[CurrentPage] SetSingleUserCurrentShareState REDUX',
  setManyUserShareState: '[CurrentPage] Set Many UserCurrentShareState REDUX',
  setGlobalShareStateToSingleUserState: '[CurrentPage] setGlobalShareStateToSingleUserState REDUX',
  setAllUserShareState: '[CurrentPage] setAllUserShareState REDUX',
  clearSingleUserShareState: '[CurrentPage] clearSingleUserCurrentShareState REDUX',
  updateShareStateBySocket: '[CurrentPage] update shared state by socket',
  updateShareStateBySocketPage: '[CurrentPage] update shared state by socket for Page',
  markAllLinkPagesAsIncomplete: '[CurrentPage] MARK ALL LINK PAGES INCOMPLETE',
  changePlaylistDurationRedux: '[CurrentPage] changePlaylistDurationRedux REDUX',
  changeLinkPageDurationByLinkPageIdRedux: '[CurrentPage] changeLinkPageDurationByLinkPageId REDUX',
  addMultiplePagesToPlaylistFromSideBar: '[CurrentPage] addMultiplePagesToPlaylistFromSideBar',
  addMultiplePagesToPlaylistFromSideBarDnD: '[CurrentPage] addMultiplePagesToPlaylistFromSideBarDnD',
  addPageToPlaylistFromSideBarDnD: '[CurrentPage] addPageToPlaylistFromSideBarDnD',
  executeAITask: '[CurrentPage] Execute AI Task',
  sendToAI: '[CurrentPage] send linkPages To AI',
  requestAISearch: '[CurrentPage] Request AI Search',
  PrepareToFind: '[CurrentPage] Prepare for search via AI',
  aiToggleVectorizeLinkPageRS: '[CurrentPage] Toggle vector for linkMage(AI)',
  aiChangeLinkPageProcessingStatus: '[CurrentPage] Change LinkPage Processing Status(AI)',
};
