import styled from 'styled-components/macro';

export const CoverDropDownWrapper = styled.div`
  display: flex;
  width: 195px;
  padding-top: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: var(--White, #FFF);
  box-shadow: 1px 1px 6px 0 rgba(122, 105, 105, 0.25);
  position: absolute;
  top: -5px;
  left: 160px;
  padding-bottom:12px;
  z-index: 7;

  ${({ position }) => (Object.keys(position)
    ? `
  top: ${position.top}px;
 left: ${position.left}px;
  `
    : '')}
  ${({ isColors }) => (isColors
    ? `
    width: 163px;
  `
    : '')}
`;

export const CoverDDTitle = styled.div`
  color: #D3C8C8;
  padding-left: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 12px; /* 100% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom:7px;
`;

export const CoverDDOptionWrapper = styled.div`
  display: flex;
  width: 194px;
  height: 30px;
  padding: 5px 5px 5px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  &:hover{
    background-color:#FFEEBF;
  }
  ${({ isRed }) => (isRed ? 'color:#D32F2F;' : '  color: #484343;')}
  ${({ isColors }) => (isColors
    ? `
    width: 100%;
  `
    : '')}
`;

export const CoverDDDivider = styled.div`
  width: 163px;
  height: 1px;
  opacity: 0.08;
  background: #1D1D1D;
  margin: 8px auto 5px;
`;

export const HalfCircle = styled.div`
  ${({ color }) => (color ? `background:${color}` : '')};
  border-radius: 11px 0 0 11px;
  width: 9px;
  height: 18px;
  transition: all ease-in-out 0.2s;
   ${({ isRightSide }) => (isRightSide ? 'transform: rotate(180deg)' : '')};
`;

export const ColorPicker = styled.div`
  display: flex;
  position: relative;
  height: 22px;
  min-width: 22px;
  align-items: center;
  justify-content: center;
${({ isExtraColors }) => (isExtraColors ? `
  width: 100%;
  justify-content: left;
` : `
  :hover ${HalfCircle} {
    height: 22px;
    width: 11px;
  }
`)}
  svg {
    position: absolute;
    top: 50%;
    left:  ${({ isExtraColors }) => (isExtraColors ? '9px' : '50%')};
    transform: translate(-50%,-50%);
  }
`;

export const ColorPickerText = styled.div`
  margin-left: 10px;
  text-transform: capitalize;
`;

export const ColorCheck = styled.div`
  position: absolute;
  right: 27px;
`;
