import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  OptionsWrapper,
} from '../../../../components/MiniPlaylist/NewPlaylist2023/styled';
import { SIX_DOTS_DD_OPTIONS_UPV } from '../../../../components/MiniPlaylist/NewPlaylist2023/helpers/enums';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { isRoleInPlaylist } from '../../../../utils/permissions';
import { NewUPVViewModes, openModalType, stopPropagation } from '../../../../utils/constants';
import {
  actionRemoveLinkPageFromPlaylist,
  actionSwitchPage,
} from '../../../../redux/currentPage/action';
import { CurrentPage } from '../../../../redux/currentPage/types';
import { actionOpenAIModal } from '../../../../redux/user/action';

const SixDotsDropWrapper = styled.div`
  width: 195px;
  position: absolute;
  z-index: 9;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-bottom: 10px;
  height: fit-content;
  ${({ isWholePlaylistOperation }) => (isWholePlaylistOperation
    ? `
    top: 22px;
    left: 311px;
  `
    : `  
          bottom: 8px;
          right: 50px;`)}

  ${({ isShort, isWholePlaylistOperation }) => (isShort && isWholePlaylistOperation ? 'left:260px;' : '')}
  ${({ forPreview }) => (forPreview
    ? `
    top: 26px;
    right: 63px;
    padding-bottom: 8px;
  `
    : '')}
  ${({ isForLib }) => (isForLib
    ? `
  top:32px;
  `
    : '')}
  bottom: -93px;
  right: -198px;
  ${({ upvCard }) => (upvCard
    ? `
    bottom: -111px;
    left: -219px;
  `
    : '')}
`;

const SixDotsDropDownUPV = ({
  isLib,
  forPreview,
  show,
  refMenu,
  isWholePlaylistOperation,
  isForLib,
  isPlaceHolder,
  currentRole,
  upvCard,
  setRenameMode,
  duplicateHandler,
  isText,
  isTitle,
  ...props
}) => {
  const {
    isViewer: isViewerEx,
    isCoEdit: isCoEditEx,
    isOwner,
  } = isRoleInPlaylist;
  const { viewMode } = useSelector((state) => state.upv);
  const isCardView = viewMode === NewUPVViewModes.card || NewUPVViewModes.expanded;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const aiOn = localStorage.getItem('aiOn');
  const isCaptionApplied = props.item?.isShowCaption;

  const userID = useSelector((state) => state.user.id);
  const currentPage = useSelector((state) => state.currentPage);

  const isPlaylistOwner = isOwner[currentRole];
  const isCoEdit = isCoEditEx[currentRole];

  const isViewer = isViewerEx[currentRole];
  const itemOwner = isLib ? true : userID === props?.item?.owner?.id;
  const isLocked = props?.item?.isRemixLocked;
  const isCanEdit = isCoEdit || itemOwner || isPlaylistOwner;
  // const canTurn = itemOwner && !!props.item?.textComponent?.id;

  const renameHandler = (e) => {
    e.stopPropagation();
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (props.setShow) {
      props.setShow(false);
    }
    if (upvCard && setRenameMode) {
      setRenameMode(true);
      return;
    }
    dispatch(
      actionCreator(CurrentPage.RenameEdit, {
        itemId: props.item?.id,
        isEdit: true,
      }),
    );
    if (props.item?.textComponent?.id) {
      dispatch(actionSwitchPage({ newBlockId: props.item?.textComponent?.id }));
    }
  };

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setShow) {
      props.setShow(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  // const createApproveButtonEx = (e) => {
  //   e.stopPropagation();
  //   if (props.setIsActive) {
  //     props.setIsActive(false);
  //   }
  //   if (isApproveBTNApplied) {
  //     props.deleteApproveButton && props.deleteApproveButton();
  //     return;
  //   }
  //   createApproveButton && createApproveButton();
  // };

  const createCaptionEx = (e) => {
    e.stopPropagation();
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (isCaptionApplied) {
      props.removeCaptionFromLinkPage && props.removeCaptionFromLinkPage();
      return;
    }
    props.addCaptionToLinkPage && props.addCaptionToLinkPage();
  };

  const exDuplicateHandler = (e) => {
    e.stopPropagation();
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (duplicateHandler) {
      duplicateHandler(props.item, props.itemIndex + 1);
    }
  };

  const aiTaskHandler = (e) => {
    e.stopPropagation();
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    dispatch(actionOpenAIModal(openModalType.AIUniversal, {
      items: [props.item],
      aiType: 'ai_send',
    }));
  };

  if (!show) return <></>;

  if (isTitle) {
    return (
      <SixDotsDropWrapper
        forPreview={forPreview}
        data-parent="miniPage"
        ref={refMenu}
        isWholePlaylistOperation={isWholePlaylistOperation}
        isShort={!isCanEdit}
        upvCard={upvCard}
      >
        <OptionsWrapper isFirst>{SIX_DOTS_DD_OPTIONS_UPV.title}</OptionsWrapper>
        <OptionsWrapper
          data-parent="miniPage"
          onClick={
          !itemOwner || isPlaceHolder || isViewer
            ? stopPropagation
            : renameHandler
        }
          disabled={!itemOwner || isPlaceHolder || isViewer}
        >
          {SIX_DOTS_DD_OPTIONS_UPV.rename}
        </OptionsWrapper>
        <OptionsWrapper
          data-parent="miniPage"
          onClick={
          ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer
            ? stopPropagation
            : exDuplicateHandler
        }
          disabled={
          ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer
        }
        >
          {SIX_DOTS_DD_OPTIONS_UPV.duplicate}
        </OptionsWrapper>
        <Divider />
        <OptionsWrapper
          isLast
          data-parent="miniPage"
          onClick={
          (!itemOwner && isCoEdit)
          || ((isCoEdit || isLocked) && !itemOwner && !isOwner && !isPlaceHolder)
            ? stopPropagation
            : deleteLinkPageClickHandler
        }
          disabled={
          (!itemOwner && isCoEdit)
          || ((isCoEdit || isLocked) && !itemOwner && !isOwner && !isPlaceHolder)
        }
        >
          {SIX_DOTS_DD_OPTIONS_UPV.delete}
        </OptionsWrapper>
      </SixDotsDropWrapper>
    );
  }

  return (
    <SixDotsDropWrapper
      forPreview={forPreview}
      data-parent="miniPage"
      ref={refMenu}
      isWholePlaylistOperation={isWholePlaylistOperation}
      isShort={!isCanEdit}
      upvCard={upvCard}
    >
      <OptionsWrapper isFirst>{SIX_DOTS_DD_OPTIONS_UPV.title}</OptionsWrapper>
      <OptionsWrapper
        data-parent="miniPage"
        onClick={
          !itemOwner || isPlaceHolder || isViewer
            ? stopPropagation
            : renameHandler
        }
        disabled={!itemOwner || isPlaceHolder || isViewer}
      >
        {SIX_DOTS_DD_OPTIONS_UPV.rename}
      </OptionsWrapper>
      <OptionsWrapper
        data-parent="miniPage"
        onClick={
          ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer
            ? stopPropagation
            : exDuplicateHandler
        }
        disabled={
          ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer
        }
      >
        {SIX_DOTS_DD_OPTIONS_UPV.duplicate}
      </OptionsWrapper>
      <Divider />
      {/* {isPlaylistOwner && ( */}
      {/* <OptionsWrapper */}
      {/*  data-parent="miniPage" */}
      {/*  onClick={ */}
      {/*    !itemOwner */}
      {/*    || isPlaceHolder */}
      {/*    || isViewer */}
      {/*    || !isCardView */}
      {/*      ? stopPropagation */}
      {/*      : createApproveButtonEx */}
      {/*  } */}
      {/*  disabled={ */}
      {/*    !itemOwner */}
      {/*    || isPlaceHolder */}
      {/*    || isViewer */}
      {/*    || !isCardView */}
      {/*  } */}
      {/* > */}
      {/*  {isApproveBTNApplied */}
      {/*    ? SIX_DOTS_DD_OPTIONS_UPV.deleteApproveButton */}
      {/*    : SIX_DOTS_DD_OPTIONS_UPV.addApprovalButton} */}
      {/* </OptionsWrapper> */}
      {/* )} */}
      <OptionsWrapper
        data-parent="miniPage"
        onClick={
          !itemOwner
          || isPlaceHolder
          || isViewer
          || !isCardView
            ? stopPropagation
            : createCaptionEx
        }
        disabled={
          !itemOwner
          || isPlaceHolder
          || isViewer
          || !isCardView
        }
      >
        {isCaptionApplied
          ? SIX_DOTS_DD_OPTIONS_UPV.deleteCaption
          : SIX_DOTS_DD_OPTIONS_UPV.addCaption}
      </OptionsWrapper>
      {/* {canTurn && ( */}
      {/*  <div */}
      {/*    data-parent="miniPage" */}
      {/*    onClick={stopPropagation} */}
      {/*    className={`${styles.editMenu__item} ${styles.editMenu__turn_info} `} */}
      {/*  > */}
      {/*    <TurnIntoSvg /> */}
      {/*    <div className={`${styles.editMenu__text}`}>Turn into</div> */}
      {/*    <img src={chevronRightSmall} alt="" className={`${styles.icon}`} /> */}
      {/*    <div className={styles.editMenu__turn_block}> */}
      {/*      <TurnIntoBlock */}
      {/*        setIsOpenMenu={() => 'setIsOpenMenu'} */}
      {/*        selectAddEditableBlock={selectAddEditableBlock} */}
      {/*        // parentRef={turnInfoRef} */}
      {/*        clear={() => {}} */}
      {/*        isUpv */}
      {/*      /> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* )} */}
      <Divider />
      {/* <OptionsWrapper */}
      {/*  data-parent="miniPage" */}
      {/*  onClick={ */}
      {/*    ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer */}
      {/*      ? stopPropagation */}
      {/*      : summarizedHandler */}
      {/*  } */}
      {/*  disabled={ */}
      {/*    ((isCoEdit || isLocked) && !itemOwner) || isPlaceHolder || isViewer */}
      {/*  } */}
      {/* > */}
      {/*  {SIX_DOTS_DD_OPTIONS_UPV.summarized} */}
      {/* </OptionsWrapper> */}
      {/* <Divider /> */}
      {aiOn && (
        <OptionsWrapper
          data-parent="miniPage"
          onClick={aiTaskHandler}
        >
          {t(SIX_DOTS_DD_OPTIONS_UPV.ai_send)}
        </OptionsWrapper>
      )}
      {aiOn && <Divider />}
      <OptionsWrapper
        isLast
        data-parent="miniPage"
        onClick={
          (!itemOwner && isCoEdit)
          || ((isCoEdit || isLocked) && !itemOwner && !isOwner && !isPlaceHolder)
            ? stopPropagation
            : deleteLinkPageClickHandler
        }
        disabled={
          (!itemOwner && isCoEdit)
          || ((isCoEdit || isLocked) && !itemOwner && !isOwner && !isPlaceHolder)
        }
      >
        {SIX_DOTS_DD_OPTIONS_UPV.delete}
      </OptionsWrapper>
    </SixDotsDropWrapper>
  );
};

export default SixDotsDropDownUPV;
