import {
  CLOSE_COPY_MESSAGE,
  SAVE_USER,
  ServiceUser,
  SHOW_ERROR,
  SHOW_TRASH_MESSAGE,
} from './types';

export const actionSaveUser = (user) => {
  return {
    type: SAVE_USER,
    payload: user,
  };
};

export const actionShowErrorMessage = () => {
  return {
    type: SHOW_ERROR,
  };
};

export const actionSetServerError = (message) => {
  return {
    type: ServiceUser.SetResponseError,
    payload: message,
  };
};

export const actionLoginUser = (email, password, isRemember, history) => ({
  type: ServiceUser.Login,
  payload: { email, password, isRemember, history },
});

export const actionGoogleLoginFlow = (data, history) => ({
  type: ServiceUser.GoogleLoginFlow,
  payload: { data, history },
});
export const actionFacebookLoginFlow = (data, history) => ({
  type: ServiceUser.FacebookLoginFlow,
  payload: { data, history },
});

export const actionSetRequestLoader = (value) => ({
  type: ServiceUser.Request,
  payload: value,
});

export const actionOpenFolder = () => {
  return {
    type: SHOW_ERROR,
  };
};

export const actionShowTrashMessage = (text) => {
  return {
    type: SHOW_TRASH_MESSAGE,
    payload: { text },
  };
};

export const actionCloseCopyMessage = () => {
  return {
    type: CLOSE_COPY_MESSAGE,
  };
};

export const actionSwitchFolder = (selectorType, folderId) => {
  return {
    type: ServiceUser.switchFolder,
    payload: { selectorType, folderId },
  };
};

export const actionBlockUnloading = () => {
  return {
    type: ServiceUser.BlockUnloading,
  };
};

export const actionUnblockUnloading = () => {
  return {
    type: ServiceUser.UnblockUnloading,
  };
};

export const actionOpenModal = (modalName, data) => ({
  type: ServiceUser.OpenModal,
  payload: { modalName, data },
});

export const actionOpenAIModal = (modalName, data) => ({
  type: ServiceUser.OpenAIModal,
  payload: { modalName, data },
});

export const actionCloseAIModal = () => ({
  type: ServiceUser.CloseAIModal,
});
export const actionOpenOverlayModal = (modalName, data) => ({
  type: ServiceUser.OpenModalOverlayModal,
  payload: { modalName, data },
});
export const actionCloseOverlayModal = () => ({
  type: ServiceUser.CloseModalOverlayModal,
});
export const actionCloseModal = () => ({
  type: ServiceUser.CloseModal,
});
export const actionLogout = () => ({
  type: ServiceUser.Logout,
});

export const actionLazyLoading = (isActive) => ({
  type: ServiceUser.LazyLoading,
  payload: { isActive },
});
