import React from 'react';
import { useSelector } from 'react-redux';
import { openModalType } from '../../utils/constants';
import AIUniversal from './AIModalUniversal';


const AIModalSelector = () => {
  const {
    openAIModal,
  } = useSelector((state) => state.user);

  return (
    <>
      {openAIModal === openModalType.AIUniversal && <AIUniversal />}
    </>
  );
};

export default AIModalSelector;
